<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="600px"
  >
    <v-container fluid>
      <div class="text-center">
        <span class="text-h3">{{ $t(title) }}</span>
      </div>
    </v-container>
    <div>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tabItem in items"
          :key="tabItem.tab"
        >
          {{ tabItem.tab }}
        </v-tab>
        <v-tab-item>
          <locations-form
            type="edit"
            :read-only="isPermission('locations.view')"
            :item="item"
            :loading="isLoading"
            @onclose="closeForm"
            @onsave="updateItem"
          />
        </v-tab-item>
        <v-tab-item v-if="isPermission('location-images.list')">
          <locations-images
            :item="item"
            @onclose="closeForm"
          />
        </v-tab-item>
        <v-tab-item v-if="isPermission('location-amenities.list')">
          <amenities
            :item="item"
            @onclose="closeForm"
          />
        </v-tab-item>
        <!-- <v-tab-item v-if="isPermission('vehicle-telematics-detail.dashboard')">
          <vehicle-telematics-chart />
        </v-tab-item> -->
      </v-tabs>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    LocationsForm: () => import("../utils/LocationsForm.vue"),
    LocationsImages: () => import("../utils/LocationsImages.vue"),
    Amenities: () => import("../utils/Amenities.vue"),
  },
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      tab: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),

    items() {
      let tab = [{ tab: this.$t("details"), content: "details" }];
      if (this.$admin.hasAccessTo("location-images.list")) {
        tab.push({
          tab: this.$t("location-images"),
          content: "location-images",
        });
      }
      if (this.$admin.hasAccessTo("location-amenities.list")) {
        tab.push({
          tab: this.$t("location-amenities"),
          content: "location-amenities",
        });
      }

      return tab;
    },

    //
    title() {
      return this.isPermission("locations.view") ? "view" : "edit";
    },
  },
  // async mounted() {
  //   if (this.authUser.isAdmin) {
  //     await this.$store.dispatch("meta/getClients", this.mdMake.make_type);
  //   }
  // },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    async updateItem(row) {
      this.isLoading = true;
      await this.$store
        .dispatch("csmsLocations/update", row)
        .then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.isLoading = false;
          this.toggleEdit();
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.isLoading = false;
        });
    },
    closeForm() {
      this.$store.commit("csmsLocations/SET_LOCATION_IMG_LIST", []);
      this.toggleEdit();
    },
  },
};
</script>
